import React from 'react';
import { useTranslation } from 'react-i18next';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

interface AdditionalUsersActionsProps {
  selectedCount: number;
  selectedRows: { key: string }[];
  loading: boolean;
  deleteAdditionalUsers: (keys: string[]) => void;
}

const AdditionalUsersActions: React.FC<AdditionalUsersActionsProps> = ({
  selectedRows,
  loading,
  deleteAdditionalUsers,
}) => {
  const { t } = useTranslation();

  const selectedKeys = selectedRows.map(row => row.key);

  return (
    <TableActionsWrapper loading={loading}>
      <TableButton
        className="mr-2"
        variant="danger"
        size="sm"
        disabled={loading}
        onClick={() => deleteAdditionalUsers(selectedKeys)}
      >
        {t('common:button.delete')}
      </TableButton>
    </TableActionsWrapper>
  );
};

export default AdditionalUsersActions;
