import React from 'react';

import HeaderLogo from 'components/HeaderLogo';
import HeaderMenu from 'components/HeaderMenu';
import SiteSelectorButton from 'components/SiteSelectorButton';

import { Container, TopBarContainer } from './styles';

interface HeaderProps {
  site: string;
  plan: string;
  email: string;
  loadingSite: boolean;
  sideMenuCollapsed: boolean;
  mobileMenuIsOpen: boolean;
  accountWithSites: boolean;
  logout: () => void;
  handleSiteSelector: () => void;
  handleSideMenu: () => void;
  handleMobileMenu: () => void;
}

const Header: React.FC<HeaderProps> = ({
  site,
  plan,
  email,
  loadingSite,
  sideMenuCollapsed,
  mobileMenuIsOpen,
  accountWithSites,
  logout,
  handleSiteSelector,
  handleSideMenu,
  handleMobileMenu,
}) => {
  return (
    <Container fixed="top" className="p-0">
      <div className="d-flex justify-content-between w-100 mobile">
        <HeaderLogo
          sideMenuCollapsed={sideMenuCollapsed}
          handleSideMenu={handleSideMenu}
          handleMobileMenu={handleMobileMenu}
        />

        <TopBarContainer className="px-4">
          <HeaderMenu
            email={email}
            logout={logout}
            mobileMenuIsOpen={mobileMenuIsOpen}
          />
          <div className="d-none d-lg-block">
            {accountWithSites && (
              <SiteSelectorButton
                site={site}
                plan={plan}
                loading={loadingSite}
                onClick={handleSiteSelector}
              />
            )}
          </div>
        </TopBarContainer>
      </div>
    </Container>
  );
};

export default Header;
