import React from 'react';
import { Switch, Route as ReactDOMRoute } from 'react-router-dom';

import Auth from 'pages/Auth';
import Home from 'pages/Home';
import Purchase from 'pages/Purchase';

import Unavailable from 'pages/Unavailable';
import BloquedAccess from 'pages/BloquedAccess';
import ReissuePayment from 'pages/ReissuePayment/New';
import ReissuePaymentBarCode from 'pages/ReissuePayment/BarCode';
import IntranetPreAuth from 'pages/IntranetPreAuth';
import RedirectPanel3 from 'pages/RedirectPanel3';
import Consumption from 'pages/Consumption';

// Emails
import Emails from 'pages/Email/Account/List';
import EmailsNew from 'pages/Email/Account/New';
import EmailsEdit from 'pages/Email/Account/Edit';
import EmailServices from 'pages/EmailServices';
import EmailBlacklistRemove from 'pages/Email/Blacklist';
import EmailRedirects from 'pages/Email/Redirects/List';
import EmailRedirectsNew from 'pages/Email/Redirects/New';
import EmailDomainRedirects from 'pages/Email/DomainRedirects/List';
import EmailDomainRedirectsNew from 'pages/Email/DomainRedirects/New';
import EmailCryptography from 'pages/Email/Cryptography';
import EmailSecuritySettings from 'pages/Email/SecuritySettings/Edit';
import EmailDkimSettings from 'pages/Email/SecuritySettings/Dkim';
import EmailDmarcSettings from 'pages/Email/SecuritySettings/Dmarc';
import EmailSpfSettings from 'pages/Email/SecuritySettings/Spf';
import EmailScript from 'pages/Email/Script';
import EmailStatistics from 'pages/Email/Statistics';
import EmailRejectedRecipient from 'pages/Email/RejectedRecipient';
import EmailReported from 'pages/Email/Reported';

// Banco de dados
import MySQLList from 'pages/Databases/MySQL/List';
import MySQLNew from 'pages/Databases/MySQL/New';
import MySQLEdit from 'pages/Databases/MySQL/Edit';
import MySQLBackup from 'pages/Databases/MySQL/Backup';
import MySQLCloudSettings from 'pages/Databases/MySQL/Cloud/Settings';
import MySQLCloudMemorySettings from 'pages/Databases/MySQL/Cloud/MemorySettings';

// Site
import TemporaryUrl from 'pages/Site/TemporaryUrl';
import SiteBackup from 'pages/Site/Backup';
import SiteCdn from 'pages/Site/Cdn';
import Redirects from 'pages/Site/Redirects/List';
import RedirectsNew from 'pages/Site/Redirects/New';
import SubDomainNew from 'pages/Site/Subdomains/New';
import SubDomainEdit from 'pages/Site/Subdomains/Edit';
import NameChange from 'pages/Site/NameChange';
import ChangePlan from 'pages/Site/ChangePlan';
import ExtraSettings from 'pages/Site/ExtraSettings';
import FtpPassword from 'pages/Site/FtpPassword';
import Quota from 'pages/Site/Quota';

// Financeiro
import AddCredit from 'pages/Finance/AddCredit';
import Statements from 'pages/Finance/Statements';
import BankSlipHistory from 'pages/Finance/BankSlipHistory';
import CreditCards from 'pages/Finance/CreditCards/List';
import CreditCardsNew from 'pages/Finance/CreditCards/New';
import CreditCardsValidate from 'pages/Finance/CreditCards/Validate';
import SuspensionPause from 'pages/Finance/SuspensionPause';
import Entities from 'pages/Finance/Entities/List';
import EntitiesNew from 'pages/Finance/Entities/New';
import EntitiesEdit from 'pages/Finance/Entities/Edit';
import Invoices from 'pages/Finance/Invoices';
import PaymentMethod from 'pages/Finance/PaymentMethod';

// Conta
import Cancellation from 'pages/Account/Cancellation';
import DealsAndPartneships from 'pages/Account/DealsAndPartneships/List';
import MX from 'pages/Account/DealsAndPartneships/MX';
import AdditionalUsers from 'pages/Account/AdditionalUsers/List';
import AdditionalUsersNew from 'pages/Account/AdditionalUsers/New';
import AdditionalServices from 'pages/Account/AdditionalServices';

// Cloud
import PhpSettings from 'pages/Cloud/PhpSettings';
import ResourceMonitoring from 'pages/Cloud/ResourceMonitoring';
import CloudSettings from 'pages/Cloud/CloudSettings';
import SitesSettings from 'pages/Cloud/SitesSettings/List';
import SitesSettingsNew from 'pages/Cloud/SitesSettings/New';
import SitesSettingsEdit from 'pages/Cloud/SitesSettings/Edit';

// Crontab
import Crontab from 'pages/Cloud/Crontab/List';
import CrontabNew from 'pages/Cloud/Crontab/New';
import CrontabEdit from 'pages/Cloud/Crontab/Edit';
import CrontabConfirm from 'pages/Cloud/Crontab/Confirm';

// Apps
import WebfacilConverted from 'pages/Apps/Webfacil/Converted';
import Wordpress from 'pages/Apps/Wordpress';
import Mautic from 'pages/Apps/Mautic';
import Courses from 'pages/Apps/Courses';
import MauticSms from 'pages/Apps/MauticSms';
import MauticSmsConsumption from 'pages/Apps/MauticSmsConsumption';
import AppsInstalados from 'pages/Apps/AppsInstalados';

// Helpdesk
import Tickets from 'pages/Helpdesk/List';
import TicketNew from 'pages/Helpdesk/New';
import Ticket from 'pages/Helpdesk/Ticket';

// Gerenciador de arquivos
import FileManager from 'pages/Site/FileManager';

// Gerenciamento de Subcontas de FTP
import FtpSubaccountsNew from 'pages/Site/FtpSubaccounts/New';
import FtpSubaccountsList from 'pages/Site/FtpSubaccounts/List';
import FtpSubaccountsEdit from 'pages/Site/FtpSubaccounts/Edit';

// Registro
import Domains from 'pages/DomainRegistry/Domains';
import DomainNew from 'pages/DomainRegistry/New';
import DomainRenew from 'pages/DomainRegistry/Renew';
import DomainContacts from 'pages/DomainRegistry/Contacts';
import InternationalDomainDns from 'pages/DomainRegistry/International/Domain/Dns';
import InternationalDomainNew from 'pages/DomainRegistry/International/Domain/New';
import InternationalDomainRenew from 'pages/DomainRegistry/International/Domain/Renew';
import InternationalDomainBuy from 'pages/DomainRegistry/International/Domain/Buy';
import InternationalDomainSettings from 'pages/DomainRegistry/International/Domain/Settings';
import InternationalDomainContactEdit from 'pages/DomainRegistry/International/Contact/Edit';
import InternationalDomainContactNew from 'pages/DomainRegistry/International/Contact/New';
import InternationalDomainTransfer from 'pages/DomainRegistry/International/Transfer';
import InternationalDomainTransferEventsHistory from 'pages/DomainRegistry/International/TransferEventsHistory';
import InternationalDomainTransferRestart from 'pages/DomainRegistry/International/TransferRestart';
import InternationalOwnNameServers from 'pages/DomainRegistry/International/OwnNameServers';
import RioDomainContactNew from 'pages/DomainRegistry/Rio/Contact/New';
import RioDomainContactEdit from 'pages/DomainRegistry/Rio/Contact/Edit';
import RioDomainDns from 'pages/DomainRegistry/Rio/Domain/Dns';
import RioDomainSettings from 'pages/DomainRegistry/Rio/Domain/Settings';
import RioDomainNew from 'pages/DomainRegistry/Rio/Domain/New';
import RioDomainRenew from 'pages/DomainRegistry/Rio/Domain/Renew';
import RioDomainChangeContact from 'pages/DomainRegistry/Rio/Domain/ChangeContact';
import BrDomainNew from 'pages/DomainRegistry/Br/Domain/New';
import BrDomainBuy from 'pages/DomainRegistry/Br/Domain/Buy';
import BrDomainDns from 'pages/DomainRegistry/Br/Domain/Dns';
import BrDomainSettings from 'pages/DomainRegistry/Br/Domain/Settings';
import BrDomainRenew from 'pages/DomainRegistry/Br/Domain/Renew';
import BrDomainContactNew from 'pages/DomainRegistry/Br/Contact/New';
import BrDomainContactEdit from 'pages/DomainRegistry/Br/Contact/Edit';
import BrDomainEntities from 'pages/DomainRegistry/Br/Entity/List';
import BrDomainEntitiesNew from 'pages/DomainRegistry/Br/Entity/New';
import BrDomainEntitiesEdit from 'pages/DomainRegistry/Br/Entity/Edit';
import BrDomainTransferNew from 'pages/DomainRegistry/Br/Transfer/New';
import BrDomainTransferConfirm from 'pages/DomainRegistry/Br/Transfer/Confirm';
import DomainTransfer from 'pages/DomainRegistry/Transfer';

import guards from './guards';

import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Auth} />
    <Route path="/acesso-indisponivel" component={Unavailable} />
    <Route path="/acesso-bloqueado" component={BloquedAccess} />
    <Route path="/home" component={Home} isPrivate />

    <Route path="/contratar" component={Purchase} isPrivate />

    <ReactDOMRoute path="/intranet-auth" component={IntranetPreAuth} />

    <Route
      path="/segunda-via/chave/:chave"
      component={ReissuePaymentBarCode}
      isPublic
    />
    <Route path="/segunda-via" component={ReissuePayment} isPublic />

    <Route
      path="/emails/estatisticas"
      component={EmailStatistics}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/emails/denunciado"
      component={EmailReported}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/emails/destino-invalido"
      component={EmailRejectedRecipient}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/emails/blacklist"
      component={EmailBlacklistRemove}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/emails/redirecionamentos"
      component={EmailRedirects}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/emails/redirecionamento/novo"
      component={EmailRedirectsNew}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/emails/redirecionamentos-dominio/adicionar"
      component={EmailDomainRedirectsNew}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['redirecionamentos.de.email.sem.site']}
    />

    <Route
      path="/emails/redirecionamentos-dominio"
      component={EmailDomainRedirects}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['redirecionamentos.de.email.sem.site']}
    />

    <Route
      path="/emails/criptografia"
      component={EmailCryptography}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/emails/configuracoes-seguranca/dkim"
      component={EmailDkimSettings}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/emails/configuracoes-seguranca/dmarc"
      component={EmailDmarcSettings}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/emails/configuracoes-seguranca/spf"
      component={EmailSpfSettings}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/emails/configuracoes-seguranca"
      component={EmailSecuritySettings}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/emails/script"
      component={EmailScript}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/emails/novo"
      component={EmailsNew}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/emails/:id/editar"
      component={EmailsEdit}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/emails"
      component={Emails}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/servico-email"
      component={EmailServices}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['email']}
    />

    <Route
      path="/banco-de-dados-mysql/adicionar"
      component={MySQLNew}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['banco.mysql']}
    />

    <Route
      path="/banco-de-dados-mysql/:id/editar"
      component={MySQLEdit}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['banco.mysql']}
    />

    <Route
      path="/banco-de-dados-mysql/backup"
      component={MySQLBackup}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['banco.mysql']}
    />

    <Route
      path="/banco-de-dados-mysql/configuracao"
      component={MySQLCloudSettings}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['servidor.cloud']}
    />

    <Route
      path="/banco-de-dados-mysql/configuracao-memoria"
      component={MySQLCloudMemorySettings}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['servidor.cloud']}
    />

    <Route
      path="/banco-de-dados-mysql"
      component={MySQLList}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['banco.mysql']}
    />

    <Route
      path="/site/endereco-provisorio"
      component={TemporaryUrl}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['endereco.provisorio']}
    />

    <Route
      path="/site/quota-de-espaco"
      component={Quota}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['quota.de.espaco']}
    />

    <Route
      path="/site/backup"
      component={SiteBackup}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['backup.de.site']}
    />

    <Route
      path="/site/cdn"
      component={SiteCdn}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['cdn.cloudflare']}
    />

    <Route
      path="/site/editar-nome"
      component={NameChange}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['troca.nome.do.site']}
    />

    <Route
      path="/site/redirecionamentos/novo"
      component={RedirectsNew}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['redirecionamentos']}
    />
    <Route
      path="/site/redirecionamentos"
      component={Redirects}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['redirecionamentos']}
    />

    <Route
      path="/subdominio/novo"
      component={SubDomainNew}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['redirecionamentos']}
    />
    <Route
      path="/subdominio/:id"
      component={SubDomainEdit}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['redirecionamentos']}
    />

    <Route
      path="/financeiro/adicionar-credito"
      component={AddCredit}
      isPrivate
    />

    <Route
      path="/financeiro/notas-fiscais"
      component={Invoices}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['notas.fiscais']}
    />

    <Route path="/financeiro/extrato" component={Statements} isPrivate />

    <Route
      path="/financeiro/historico-boletos"
      component={BankSlipHistory}
      isPrivate
    />

    <Route
      path="/financeiro/forma-pagamento"
      component={PaymentMethod}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['financeiro']}
    />

    <Route
      path="/financeiro/cartoes-credito/cadastrar"
      component={CreditCardsNew}
      isPrivate
    />
    <Route
      path="/financeiro/cartoes-credito/validacao/:id"
      component={CreditCardsValidate}
      isPrivate
    />
    <Route
      path="/financeiro/cartoes-credito"
      component={CreditCards}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['financeiro']}
    />

    <Route
      path="/financeiro/suspender-congelamento"
      component={SuspensionPause}
      isPrivate
    />

    <Route
      path="/financeiro/entidades/cadastrar"
      component={EntitiesNew}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['financeiro']}
    />
    <Route
      path="/financeiro/entidades/:id"
      component={EntitiesEdit}
      isPrivate
    />
    <Route
      path="/financeiro/entidades"
      component={Entities}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['financeiro']}
    />

    <Route
      path="/cancelamento"
      component={Cancellation}
      guards={[guards.basic]}
      requiredOptions={['cancelamento']}
      isPrivate
    />

    <Route path="/parcerias-e-promocoes/mx-cursos" component={MX} isPrivate />
    <Route
      path="/parcerias-e-promocoes"
      component={DealsAndPartneships}
      isPrivate
    />

    <Route
      path="/cloud/crontab/novo"
      component={CrontabNew}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['servidor.cloud']}
    />

    <Route
      path="/cloud/crontab/:id"
      component={CrontabEdit}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['servidor.cloud']}
    />

    <Route
      path="/cloud/crontab"
      component={Crontab}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['servidor.cloud']}
    />

    <Route
      path="/cloud/crontab-confirmar"
      component={CrontabConfirm}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['servidor.cloud']}
    />

    <Route
      path="/cloud/configuracoes-php"
      component={PhpSettings}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['servidor.cloud']}
    />

    <Route
      path="/cloud/monitoramento-recursos"
      component={ResourceMonitoring}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['servidor.cloud']}
    />

    <Route
      path="/cloud/configuracoes-cloud"
      component={CloudSettings}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['servidor.cloud']}
    />

    <Route
      path="/cloud/configuracoes-sites/editar/:site"
      component={SitesSettingsEdit}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['servidor.cloud']}
    />

    <Route
      path="/cloud/configuracoes-sites/novo"
      component={SitesSettingsNew}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['servidor.cloud']}
    />

    <Route
      path="/cloud/configuracoes-sites"
      component={SitesSettings}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['servidor.cloud']}
    />

    <Route
      path="/helpdesk/ticket/novo"
      component={TicketNew}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['helpdesk']}
    />
    <Route
      path="/helpdesk/ticket/:id"
      component={Ticket}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['helpdesk']}
    />
    <Route
      path="/helpdesk"
      component={Tickets}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['helpdesk']}
    />

    <Route
      path="/site/gerenciador-de-arquivos"
      component={FileManager}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['gerenciador.de.arquivos']}
    />

    <Route
      path="/site/senha-ftp"
      component={FtpPassword}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['senha.de.ftp']}
    />

    <Route
      path="/site/subcontas-ftp"
      component={FtpSubaccountsList}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['subcontas.de.ftp']}
    />
    <Route
      path="/site/subconta-ftp/adicionar"
      component={FtpSubaccountsNew}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['subcontas.de.ftp']}
    />
    <Route
      path="/site/subconta-ftp/:subconta/editar"
      component={FtpSubaccountsEdit}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['subcontas.de.ftp']}
    />
    <Route
      path="/site/editar-plano"
      component={ChangePlan}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['troca.de.plano']}
    />
    <Route
      path="/site/configuracoes-extras"
      component={ExtraSettings}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['site.configuracoes.extras']}
    />

    <Route
      path="/aplicativos/webfacil/migrado"
      component={WebfacilConverted}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['webfacil.migrado.wordpress']}
    />
    <Route path="/aplicativos/wordpress" component={Wordpress} isPrivate />
    <Route
      path="/aplicativos/mautic-sms/consumo"
      component={MauticSmsConsumption}
      isPrivate
    />
    <Route path="/aplicativos/mautic-sms" component={MauticSms} isPrivate />
    <Route path="/aplicativos/mautic" component={Mautic} isPrivate />

    <Route path="/aplicativos/cursos" component={Courses} isPrivate />

    <Route path="/registro/dominios" component={Domains} isPrivate />
    <Route path="/registro/dominio-registrar" component={DomainNew} isPrivate />

    <Route path="/registro/contatos" component={DomainContacts} isPrivate />
    <Route
      path="/registro/dominio-internacional-dns/:id"
      component={InternationalDomainDns}
      isPrivate
    />
    <Route
      path="/registro/dominio-internacional-configuracao/:id"
      component={InternationalDomainSettings}
      isPrivate
    />
    <Route
      path="/registro/dominio-internacional-registrar"
      component={InternationalDomainNew}
      isPrivate
    />
    <Route
      path="/registro/dominio-internacional-renovar/:id"
      component={InternationalDomainRenew}
      isPrivate
    />
    <Route
      path="/registro/contato-internacional-editar/:id"
      component={InternationalDomainContactEdit}
      isPrivate
    />
    <Route
      path="/registro/dominio-internacional-comprar/:id"
      component={InternationalDomainBuy}
      isPrivate
    />
    <Route
      path="/registro/contato-internacional-adicionar"
      component={InternationalDomainContactNew}
      isPrivate
    />
    <Route
      path="/registro/contato-rio-adicionar"
      component={RioDomainContactNew}
      isPrivate
    />
    <Route
      path="/registro/contato-rio-editar/:id"
      component={RioDomainContactEdit}
      isPrivate
    />
    <Route
      path="/registro/dominio-rio-dns/:id"
      component={RioDomainDns}
      isPrivate
    />
    <Route
      path="/registro/dominio-rio-configuracao/:id"
      component={RioDomainSettings}
      isPrivate
    />
    <Route
      path="/registro/dominio-rio-registrar"
      component={RioDomainNew}
      isPrivate
    />
    <Route
      path="/registro/dominio-rio-renovar/:id"
      component={RioDomainRenew}
      isPrivate
    />
    <Route
      path="/registro/dominio-rio-contato-editar/:id"
      component={RioDomainChangeContact}
      isPrivate
    />

    <Route
      path="/registro/dominio-br-registrar"
      component={BrDomainNew}
      isPrivate
    />

    <Route
      path="/registro/dominio-br-comprar/:id"
      component={BrDomainBuy}
      isPrivate
    />

    <Route
      path="/registro/dominio-br-dns/:id"
      component={BrDomainDns}
      isPrivate
    />

    <Route
      path="/registro/dominio-br-configuracao/:id"
      component={BrDomainSettings}
      isPrivate
    />

    <Route
      path="/registro/dominio-br-renovar/:id"
      component={BrDomainRenew}
      isPrivate
    />

    <Route
      path="/registro/contato-br-adicionar"
      component={BrDomainContactNew}
      isPrivate
    />

    <Route
      path="/registro/contato-br-editar/:id"
      component={BrDomainContactEdit}
      isPrivate
    />

    <Route
      path="/registro/entidades-br"
      component={BrDomainEntities}
      isPrivate
    />

    <Route
      path="/registro/entidade-br-adicionar"
      component={BrDomainEntitiesNew}
      isPrivate
    />

    <Route
      path="/registro/entidade-br-editar/:id"
      component={BrDomainEntitiesEdit}
      isPrivate
    />

    <Route
      path="/registro/transferencia"
      component={DomainTransfer}
      isPrivate
    />

    <Route
      path="/registro/transferencia-br"
      component={BrDomainTransferNew}
      isPrivate
    />

    <Route
      path="/registro/transferencia-br-confirmar"
      component={BrDomainTransferConfirm}
      isPrivate
    />

    <Route
      path="/registro/transferencia-internacional/:id/historico"
      component={InternationalDomainTransferEventsHistory}
      isPrivate
    />

    <Route
      path="/registro/transferencia-internacional/:id/reiniciar"
      component={InternationalDomainTransferRestart}
      isPrivate
    />

    <Route
      path="/registro/transferencia-internacional"
      component={InternationalDomainTransfer}
      isPrivate
    />

    <Route
      path="/registro/dns-proprio-internacional"
      component={InternationalOwnNameServers}
      isPrivate
    />

    <Route path="/registro/renovar" component={DomainRenew} isPrivate />

    <Route
      path="/usuarios-adicionais/adicionar"
      component={AdditionalUsersNew}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['usuarios.adicionais']}
    />
    <Route
      path="/usuarios-adicionais"
      component={AdditionalUsers}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['usuarios.adicionais']}
    />

    <Route
      path="/trafego-espaco"
      component={Consumption}
      isPrivate
      guards={[guards.basic]}
      requiredOptions={['consumo']}
    />

    <Route
      path="/servicos-adicionais"
      component={AdditionalServices}
      isPrivate
    />

    <Route path="/painel3" component={RedirectPanel3} isPrivate singlePage />

    <Route
      path="/aplicativos/apps-instalados"
      component={AppsInstalados}
      isPrivate
    />
  </Switch>
);

export default Routes;
