import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaIdCard } from 'react-icons/fa';
import { Formik } from 'formik';
import { Form, Card, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';

import api from 'services/api';
import toast from 'services/toast';
import history from 'services/history';
import swal from 'services/swal';

import useAdditionalUsers from 'hooks/useAdditionalUsers';

import { Link } from 'react-router-dom';
import { Container } from './styles';

interface NewAdditionalUserFormValues {
  email: string;
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const { refetch } = useAdditionalUsers();

  async function createAdditionalUser(values: NewAdditionalUserFormValues) {
    try {
      await api.post(`painel/v1/usuario-adicional`, {
        emailUsuarioAdicional: values.email,
      });

      toast.fire(t('pages:additionalUsersNew.success'));

      refetch();

      history.push(`/usuarios-adicionais`);
    } catch (err) {
      swal.fire({
        title: t('pages:additionalUsersNew.fail'),
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    }
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:additionalUsers.title')}
        description={t('titles:additionalUsers.description')}
        icon={<FaIdCard color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          startOpen
          title={t('pages:additionalUsersNew.title')}
          description={t('pages:additionalUsersNew.description')}
          directHelpLink="https://ajuda.hostnet.com.br/usuarios-no-painel-de-controle/"
          helpContent={
            <div>
              <div>
                <b>{t('common:important')}</b>:
              </div>
              <div> - {t('pages:additionalUsersNew.tip1')}</div>
              <div> - {t('pages:additionalUsersNew.tip2')}</div>
              <div> - {t('pages:additionalUsersNew.tip3')}</div>
            </div>
          }
          helpLinks={[
            {
              text: t('common:learnMoreInHelpCenter'),
              url:
                'https://ajuda.hostnet.com.br/usuarios-no-painel-de-controle/',
            },
          ]}
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            email: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(t('validations:invalidEmail'))
              .required(t('validations:requiredField')),
          })}
          onSubmit={values => createAdditionalUser(values)}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row} controlId="additionalUserNew.email">
                  <Form.Label column sm={2} className="required">
                    {t('common:email')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.email && !!props.errors.email}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  variant="primary"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/usuarios-adicionais"
                  disabled={props.isSubmitting}
                >
                  {t('common:label.back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
